const tools = {
  /**
    GMT时间转正常字符串
    type = 1  => 2020-06-10
    type = 2 =>2020-06-10 12:00:00

    layback=0=>推后0天
  */
  GMTToStr: function (time, type = 1,layback=0) {
    if (!time) {
      console.log("时间不能为空");
      return false;
    }
    if(typeof(time)=='string'&&time.indexOf('T')<=-1){
      time = time.replace(/\-/g, "/")
    }
    let Str,
      date = new Date(time);
      let targetday_milliseconds = date.getTime() + 1000 * 60 * 60 * 24 * layback;　　
      date.setTime(targetday_milliseconds); //注意，这行是关键代码

      let yy = date.getFullYear(),
      mm = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1),
      dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
      ff = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(),
      ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    if (type == 1) {
      Str = `${yy}-${mm}-${dd}`;
      return Str
    } else if (type == 2) {
      Str = `${yy}-${mm}-${dd} ${hh}:${ff}:${ss}`;
      return Str
    } else if (type == 3) {
      Str = `${yy}${mm}${dd}${hh}${ff}${ss}`;
      return Str
    }else {
      return time;
    }
  },
  /**
    获取时间
    day = 0  =>获取当天时间
          1  =>获取明天时间
         -1  =>获取昨天时间
    type = 1 =>2020-06-10 
         = 2 =>2020-06-10 12:00:00
  */
  GetDay:function(day,type=2){
    let date = new Date();　　
    let targetday_milliseconds = date.getTime() + 1000 * 60 * 60 * 24 * day;　　
    date.setTime(targetday_milliseconds); //注意，这行是关键代码
    let yy = date.getFullYear();　　
    let mm = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);　　
    let dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    let ff = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    let ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();　
    if (type == 1) {
      return `${yy}-${mm}-${dd}`
    } else if (type == 2) {
      return `${yy}-${mm}-${dd} ${hh}:${ff}:${ss}`
    } else {
      return date;
    }
  },
  // 将数字转换成金额显示  1:分转换  2.元不转
  ToMoney: function (num,type = 1) {
    if (num) {
        if (isNaN(num)) {
            alert('金额中含有不能识别的字符');
            return;
        }
        num = typeof num === 'string' ? parseFloat(num) : num // 判断是否是字符串如果是字符串转成数字
        num = parseInt(num)
        if(type == 1){
          num = this.floatDiv(num,100)
        }
        num = num.toFixed(2); // 保留两位
        num = parseFloat(num); // 转成数字
        num = num.toLocaleString(); // 转成金额显示模式
        // 判断是否有小数
        if (num.indexOf('.') === -1) {
            num = num + '.00';
        } else {
            num = num.split('.')[1].length < 2 ? num + '0' : num;
        }
        return num; // 返回的是字符串23,245.12保留2位小数
    } else {
        return num = 0;
    }
  },
  //素材类型切换
  changeMaterialtypeToStr(num){
    if(num==2001){
      return "文本"
    } else if(num==2002){
      return "图片"
    } else if(num==2003){
      return "语音"
    } else if(num==2004){
      return "视频"
    } else if(num==2005){
      return "链接"
    } else if(num==2013){
      return "小程序"
    } else if(num==2016){
      return "音乐"
    }else if(num==2017){
      return '视频号'
    }

  },
  //消息状态装换
  changeMsgtypeToStr(num){
    if(num==0){
      return "未发送"
    } else if(num==3){
      return "取消"
    } else if(num==10){
      return "成功"
    } else if(num==11){
      return "发送失败"
    } else if(num==20){
      return "重发中"
    } 
  },

  //加
  floatAdd: function (arg1, arg2) {
    var r1, r2, m;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  },
  //减
  floatSub: function (arg1, arg2) {
      var r1, r2, m, n;
      try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
      try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
      m = Math.pow(10, Math.max(r1, r2));
      //动态控制精度长度    
      n = (r1 >= r2) ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n); 
  },
  //乘
  floatMul: function (arg1, arg2 =1) {
      var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
      try { m += s1.split(".")[1].length } catch (e) {}
      try { m += s2.split(".")[1].length } catch (e) {}
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  },
  //除   
  floatDiv: function(arg1, arg2 = 100){
      var t1 = 0, t2 = 0, r1, r2;
      try { t1 = arg1.toString().split(".")[1].length } catch (e) {}
      try { t2 = arg2.toString().split(".")[1].length } catch (e) {}
      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
      return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  //导出
  exportExcl(name,data){
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = name + this.GMTToStr(new Date(), 3);
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  },

  //导出图片
  exportImage(sourceSrc,name){
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name+'.png' || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src =  `${sourceSrc}?t=${new Date().getTime()}`;
  },

  isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
}
export default tools